import '@brightspace-ui/core/components/button/button.js';
import { bodyCompactStyles, heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import '../../../../../../shared/components/general/nova-lang-select-button/nova-lang-select-button.js';
import { skillswaveButtonStyles, skillswaveHeader1Styles, skillswaveParagraphSubtleStyles } from '@/app/shared/styles/skillswave-text-styles.js';
import { LocalizeNova } from '../../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '../../../../../../shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';

class ClientOnboardingWelcome extends RequesterMixin(NovaFlowStepMixin(LocalizeNova(SkeletonMixin(LitElement)))) {

  static get styles() {
    return [
      bodyCompactStyles,
      heading1Styles,
      skillswaveButtonStyles,
      skillswaveHeader1Styles,
      skillswaveParagraphSubtleStyles,
      css`
        :host {
          display: block;
          height: 100%;
          width: 100%;
        }

        d2l-button {
          width: 100%;
        }

        nova-lang-select-button {
          margin-right: 20px;
        }

        #skillswave-logo {
          margin: 13px 0 0 32px;
          max-width: 120px;
        }

        .content-container {
          display: flex;
          height: 100%;
          width: 100%;
        }

        .primary {
          display: flex;
          flex: 1;
          flex-direction: column;
        }

        .side-panel {
          display: block;
          flex-shrink: 0;
          height: 100%;
          max-width: 25%;
          overflow: hidden;
          width: 100%;
        }
        .side-panel img {
          height: 100%;
          width: 100%;
        }

        .primary-content-container {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .primary-content {
          max-width: 554px;
        }

        .skillswave-heading-1 {
          margin-bottom: 0;
          margin-top: 0;
        }

        .skillswave-paragraph-subtle {
          margin-bottom: 32px;
        }

        .heading-container {
          align-items: flex-start;
          display: flex;
          flex-direction: row;
          height: 80px;
          justify-content: space-between;
          margin-bottom: 40px;
          max-height: 80px;
          padding-top: 14px;
        }

        .progress-bar-container {
          display: flex;
          justify-content: center;
          margin-bottom: 46px;
        }

        @media (max-width: 1024px) {
          .side-panel {
            display: none;
          }

          .content-container {
            display: block;
          }

          .primary-content {
            max-width: 100%;
            padding: 0 24px;
          }
        }
      `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  render() {
    return html`
      <div class="content-container">
        <div class="primary">
          <div class="heading-container">
            <img id="skillswave-logo" src="/assets/img/skillswave-logo-black.svg" alt="Skillswave Logo" />
            <nova-lang-select-button></nova-lang-select-button>
          </div>
          <div class="primary-content-container">
            <div class="progress-bar-container">
              Step ${this.stepIndex} of ${this.stepCount}
            </div>
            <div class="primary-content">
              <h1 class="skillswave-heading-1">
                ${this.localize('view-client-onboarding.client-onboarding-welcome.congratulations.1', { userName: this.session.user.getDisplayName() })}
                <br />
                <br />
                ${this.localize('view-client-onboarding.client-onboarding-welcome.congratulations.2', { strong: this.l10nTags.strong() })}
              </h1>
              <p class="skillswave-paragraph-subtle">
                ${this.localize('view-client-onboarding.client-onboarding-welcome.description', { tenantName: this.session.tenant.name })}
              </p>
              <d2l-button primary @click=${this.goNext}>
                <span class="skillswave-button-text">${this.localize('view-client-onboarding.client-onboarding-welcome.getStarted')}</span>
              </d2l-button>
            </div>
          </div>
        </div>
        <div class="side-panel">
          <img src="/assets/img/client-onboarding-side-panel.svg" alt="Decorative side panel" />
        </div>
      </div>
    `;
  }
}

window.customElements.define('client-onboarding-welcome', ClientOnboardingWelcome);

