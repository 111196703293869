import '@brightspace-ui-labs/autocomplete/autocomplete-input-text.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';
import '@brightspace-ui/core/components/button/button.js';

import { css, html, LitElement, nothing } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { LocalizeNova } from '@/app/shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '@/app/shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';

class ClientOnboardingDepartment extends RequesterMixin(NovaFlowStepMixin(LocalizeNova(LitElement))) {

  static get properties() {
    return {
      lightcastCompanyId: { type: String, attribute: 'lightcast-company-id' },
      _selectedDepartment: { type: String },
      _departmentList: { type: Array },
    };
  }

  static get styles() {
    return [
      selectStyles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();

    this._departmentList = [];
  }

  async connectedCallback() {
    super.connectedCallback();

    this.client = this.requestInstance('d2l-nova-client');
  }

  async updated(changedProperties) {
    if (changedProperties.has('_selectedDepartment')) {
      this.data = {
        department: this._selectedDepartment,
      };
      this.nextEnabled = this._selectedDepartment !== undefined || this._departmentList.length === 0;
    }

    if (changedProperties.has('lightcastCompanyId')) {
      if (this.lightcastCompanyId) {
        const lightcastDepartmentsData = await this.client.lightcastLotCareerArea(this.lightcastCompanyId);
        if (lightcastDepartmentsData.lotCareerAreas.length) {
          this._departmentList = lightcastDepartmentsData.lotCareerAreas;

          this.requestUpdate();
        }
      }

      this.nextEnabled = this._departmentList.length === 0;
    }
  }

  _onSelection(e) {
    if (e.target.value) {
      this._selectedDepartment = e.target.value;
    }
  }

  render() {
    return html`
      <div class="client-onboarding-department-description">
        What is your team in charge of? We'll make sure the learning fits to make every day a bigger success.
      </div>
      <div class="client-onboarding-department-data">
        <select
					aria-label="Choose a department:"
					class="d2l-input-select"
          @input=${this._onSelection}>
          ${this._selectedDepartment ? nothing : html`<option value="">Select a department</option>` }
					${this._departmentList.map(department => html`
            <option>${department}</option>
          `)}
				</select>
      </div>
      <div>
        ${!this.blockBack
    ? html`<d2l-button class="nova-flow-previous" @click=${this.goBack}>${this.localize('general.button-text.previous')}</d2l-button>`
    : nothing }
        <d2l-button class="nova-flow-next" primary ?disabled=${!this.nextEnabled} @click=${this.goNext}>${this.localize('general.button-text.next')}</d2l-button>
      </div>
    `;
  }

}

window.customElements.define('client-onboarding-department', ClientOnboardingDepartment);
