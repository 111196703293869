import '@brightspace-ui/core/components/switch/switch.js';
import '@brightspace-ui/core/components/button/button-icon';
import '@brightspace-ui/core/components/status-indicator/status-indicator';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';
import '@brightspace-ui/core/components/filter/filter.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set-value.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui/core/components/table/table-controls.js';
import '@brightspace-ui/core/components/table/table-col-sort-button.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';
import '@brightspace-ui/labs/components/pager-numeric.js';

import '../../../shared/components/general/app-link/app-link.js';
import '../../../shared/components/dialog/confirmation-dialog/confirmation-dialog.js';
import '../../../shared/components/inputs/nova-htmleditor/nova-menu-dropdown.js';
import '../../../shared/components/inputs/nova-htmleditor/nova-menu-dropdown-button.js';

import { navigator as nav } from 'lit-element-router';

import { bodySmallStyles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { css, html, LitElement, nothing } from 'lit';

import { getDisplayName } from '../../../../shared/methods.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaPermissionMixin } from '../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

class UsersTable extends NovaPermissionMixin(LocalizeNova(nav(LitElement))) {
  static get properties() {
    return {
      users: { type: Array, attribute: false },
      allRoles: { type: Array, attribute: false },
      _searchQuery: { type: String, attribute: false },
      _sortFacet: { type: String, attribute: false },
      _activeStatus: { type: String, attribute: false },
      _deleteDialogOpen: { type: Boolean, attribute: false },
      _sortDesc: { type: Boolean, attribute: false },
      _pageNumber: { type: Number, attribute: false },
      _totalUsers: { type: Number, attribute: false },
      _isLoading: { type: Boolean, attribute: false },
      _selectedUser: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      tableStyles,
      labelStyles,
      css`
        .filter-and-search-container {
          display: flex;
          margin-bottom: 24px;
        }

        .filter-and-search-container .filter-text-container {
          margin-left: 12px;
          margin-right: 2px;
        }

        .filter-and-search-container d2l-input-search {
          flex: 1;
          max-width: 300px;
        }

        .add-users-button {
          margin-left: auto;
        }

        .active-column {
          font-weight: bold !important;
          text-align: center !important;
        }

        .loading-spinner {
          padding: 20px;
          text-align: center;
        }
      `];
  }

  constructor() {
    super();
    this.users = [];
    this.allRoles = [];
    this._searchQuery = '';
    this._activeStatus = 'active';
    this._deleteDialogOpen = false;
    this._sortDesc = false;
    this._sortFacet = '';
    this._pageNumber = 1;
    this._totalUsers = 0;
    this._isLoading = false;
    this._usersPerPage = 10;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  firstUpdated() {
    this._fetchUsers();
  }

  async _fetchUsers() {
    this._isLoading = true;

    const searchParams = {
      search: this._searchQuery,
      from: (this._pageNumber - 1) * this._usersPerPage,
      size: this._usersPerPage,
      sort: this._sortFacet,
      tenantId: this.session._tenant.id,
      sortOrder: this._sortDesc ? 'desc' : 'asc',
      active: this._activeStatus === 'active',
    };

    try {
      const response = await this.client.searchUsers(searchParams);

      // Update the component's data properties with the new response structure
      this.users = response.results; // results array from the new API
      this._totalUsers = response.total; // total number of users
    } catch (error) {
      this.users = [];
      this._totalUsers = 0;
      console.error('Error fetching users:', error);
    } finally {
      this._isLoading = false;
    }
  }

  async _dialogClose(e) {
    const { action } = e.detail;
    this._deleteDialogOpen = false;

    if (action === 'toggleActiveStatus') {
      this._selectedUser.active = !this._selectedUser.active;
      try {
        await this.client.toggleUserActiveStatus(this._selectedUser);
        const message = this._selectedUser?.active ? this.localize('edit-permissions.permissions-table.activateUserRolesConfirmation.toast') : this.localize('edit-permissions.permissions-table.deactivateUserRolesConfirmation.toast');
        this.session.toast({ type: 'default', message });
        this._fetchUsers();
      } catch (error) {
        if (error.status !== 405) {
          const message = this._selectedUser?.active ? this.localize('edit-permissions.permissions-table.activateUserRolesConfirmationFail.toast') : this.localize('edit-permissions.permissions-table.deactivateUserRolesConfirmationFail.toast');
          this.session.toast({ type: 'critical', message });
        }
      }
    }
  }
  async _handleStateChange(e) {
    const userGuid = e.target['data-guid'];
    this._selectedUser = this.users.find(u => u.guid === userGuid);
    this._deleteDialogOpen = true;
  }

  get _confirmationDialogTemplate() {
    return html`
      <confirmation-dialog
        ?opened=${this._deleteDialogOpen}
        type="toggleUserActiveStatusConfirmation"
        .data="${this._selectedUser}"
        @d2l-dialog-close=${this._dialogClose}>
      </confirmation-dialog>`;
  }

  _emptyStateTemplate() {
    if (this.users.length !== 0 || this._isLoading) return nothing;
    html`
        <tr>
          <td colspan="6" class="empty-state-container">
            <div class="empty-state">
              <d2l-empty-state-illustrated illustration-name="desert-road" title-text="${this.localize('edit-permissions.userPermissions.error.noUsers')}" description="${this.localize('edit-permissions.userPermissions.error.description')}">
              </d2l-empty-state-illustrated>
            </div>
          </td>
        </tr>`;
  }

  loadingSpinnerTemplate() {
    return html`
        <tr>
          <td colspan="6" class="empty-state-container">
              <div class="loading-spinner"><d2l-loading-spinner size="80"></d2l-loading-spinner></div>
          </td>
        </tr>`;
  }

  _actionColumnTemplate(user) {
    if (user.lastLoginTime) return html`
      <d2l-button-icon
        ?disabled="${!this.canUpdate || !user.active}"
        .data-guid="${user.guid}"
        text="edit"
        icon="tier1:edit"
        @click="${this._editUserClick}">
      </d2l-button-icon>`;

    return html`
      <nova-menu-dropdown icon="tier1:more" description="${this.localize('users-table.column.actions')}" hide-chevron>
        <d2l-menu-item data-user="${user.guid}" text="${this.localize('users-table.reinvite.menu')}" @click=${this.resendInvitation}></d2l-menu-item>
        <d2l-menu-item data-user="${user.guid}" text="${this.localize('users-table.cancel-invite.menu')}" @click=${this.cancelInvitation}></d2l-menu-item>
      </nova-menu-dropdown>`;
  }

  _activeColumnTemplate(user) {
    if (!user.lastLoginTime) return html`${this.localize('users-table.invited')}`;
    return html`
            <d2l-switch
              .data-guid="${user.guid}"
              text-position="hidden"
              ?disabled="${!this.canUpdate}"
              text="${this.localize('users-table.userActiveSwitchLabel')}"
              @change="${this._handleStateChange}"
              ?on="${user?.active}">
            </d2l-switch>`;
  }

  async resendInvitation(e) {
    const userGuid = e.target.getAttribute('data-user');
    await this.client.resendInvitation(userGuid);
    this.session.toast({ type: 'success', message: this.localize('users-table.reinvite.success') });
  }

  async cancelInvitation(e) {
    const userGuid = e.target.getAttribute('data-user');
    await this.client.cancelInvitation(userGuid);
    await this._fetchUsers();
    this.session.toast({ type: 'success', message: this.localize('users-table.cancel-invite.success') });
    this.users = this.users.filter(u => u.guid !== userGuid);
  }

  _userTableBodyTemplate() {
    if (this._isLoading) return this.loadingSpinnerTemplate();
    return html`
            ${this._emptyStateTemplate()}
            ${this.users.map(user => html`
              <tr>
                <td>${getDisplayName(user)}</td>
                <td class="emails-column">${user?.email}</td>
                <td class="roles-column">${this._renderRoleList(user?.roles)}</td>
                <td class="d2l-body-small active-column">${this._activeColumnTemplate(user)}</td>
                <td class="action-column">${this._actionColumnTemplate(user)}</td>
            </tr>
            `)}`;
  }

  _userTableHeaderTemplate() {
    return html`
          <tr class="table-header">
            <th><d2l-table-col-sort-button id="displayName" ?nosort="${this._sortFacet !== 'displayName'}" ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.name')}</d2l-table-col-sort-button></th>
            <th><d2l-table-col-sort-button id="email" ?nosort=${this._sortFacet !== 'email'} ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.email')}</d2l-table-col-sort-button></th>
            <th><d2l-table-col-sort-button id="roles" ?nosort=${this._sortFacet !== 'roles'} ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.permissions')}</d2l-table-col-sort-button></th>
            <th><d2l-table-col-sort-button id="status" ?nosort=${this._sortFacet !== 'status'} ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.status')}</d2l-table-col-sort-button></th>
            <th>${this.localize('edit-permissions.permissions-table.header.action')}</th>
          </tr>
    `;
  }

  _userTablePaginatorTemplate() {
    return html`<d2l-labs-pager-numeric
          id="pagination"
          @d2l-labs-pager-numeric-page-change=${this._handlePageChanged}
          page-number=${this._pageNumber}
          max-page-number=${this._maxPageNumber}>
        </d2l-labs-pager-numeric>`;
  }

  _userTableSearchFilterTemplate() {
    return html`
      <div class="filter-and-search-container">
        <d2l-input-search @d2l-input-search-searched="${this._handleSearch}" placeholder="${this.localize('edit-permissions.permissions-table.searchLabel')}..." label="${this.localize('edit-permissions.permissions-table.searchLabel')}"></d2l-input-search>
        <div class="filter-text-container"><p class="d2l-label-text">${this.localize('edit-permissions.permissions-table.filter.introductoryText')}:</p></div>
        <d2l-filter @d2l-filter-change="${this._handleFilterChange}">
          <d2l-filter-dimension-set ?selection-single=${true} search-type="none" key="status" text="${this.localize('edit-permissions.permissions-table.filter.status')}">
            <d2l-filter-dimension-set-value key="active" text="${this.localize('edit-permissions.permissions-table.filter.active')}" ?selected="${this._activeStatus === 'active'}"></d2l-filter-dimension-set-value>
            <d2l-filter-dimension-set-value key="inactive" text="${this.localize('edit-permissions.permissions-table.filter.inactive')}" ?selected="${this._activeStatus === 'inactive'}"></d2l-filter-dimension-set-value>
          </d2l-filter-dimension-set>
        </d2l-filter>
        <d2l-button class="add-users-button" @click="${this.addUser}">Add users</d2l-button>
      </div>
    `;
  }

  addUser() {
    this.navigate('/settings/user-management/create');
  }

  render() {
    return html`
      ${this._userTableSearchFilterTemplate()}
      <d2l-table-wrapper>
        <table class="d2l-table">
          <thead>${this._userTableHeaderTemplate()}</thead>
          <tbody>
            ${this._userTableBodyTemplate()}
          </tbody>
        </table>
      </d2l-table-wrapper>
      ${this._userTablePaginatorTemplate()}
      ${this._confirmationDialogTemplate}
    `;
  }

  get _maxPageNumber() {
    return this._totalUsers > 0 ? Math.ceil(this._totalUsers / this._usersPerPage) : 1;
  }

  _getRoleTag(role) {
    const roleName = this.allRoles?.find(r => r.roleId === role.roleId)?.roleName;
    return html`<d2l-tag-list-item text="${roleName}"></d2l-tag-list-item>`;
  }

  _renderRoleList(roles = []) {
    const filteredRoles = roles.filter(role => this.allRoles.some(r => r.roleId === role.roleId));

    if (!filteredRoles?.length) {
      return html `<d2l-tag-list><d2l-tag-list-item text="Learner"></d2l-tag-list-item></d2l-tag-list>`;
    } else {
      return html `<d2l-tag-list>
      ${filteredRoles.map(role => this._getRoleTag(role))}
    </d2l-tag-list>`;
    }
  }

  _editUserClick(e) {
    const guid = e.target['data-guid'];
    this.dispatchEvent(new CustomEvent('edit-user', { detail: { guid } }));
  }

  _handlePageChanged(e) {
    this._pageNumber = e.detail.page;
    this._fetchUsers();
  }

  _handleSearch(e) {
    clearTimeout(this._searchTimeout);
    this._searchTimeout = setTimeout(() => {
      this._sortFacet = '';
      this._searchQuery = e.detail.value;
      this._pageNumber = 1;
      this._fetchUsers();
    }, 300);
  }

  _handleSort(e) {
    const desc = e.target.hasAttribute('desc');
    this._sortFacet = e.target.id;
    this._sortDesc = !desc;
    this._pageNumber = 1;
    this._fetchUsers();
  }

  _handleFilterChange(e) {
    if (e.detail.dimensions[0].cleared) {
      this._activeStatus = '';
    } else {
      this._activeStatus = e.detail.dimensions[0].changes[0].valueKey;
    }
    this._pageNumber = 1;
    this._fetchUsers();
  }
}

window.customElements.define('users-table', UsersTable);
