import '@brightspace-ui/core/components/button/button.js';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';

import { css, html, LitElement, nothing } from 'lit';
import { LocalizeNova } from '../../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '../../../../../../shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';

export const OBJECTIVES = {
  MY_COMPANY: 'my-company',
  MY_TEAM: 'my-team',
  TESTING: 'testing',
};

class ClientOnboardingObjective extends NovaFlowStepMixin(LocalizeNova(LitElement)) {

  static get properties() {
    return {
      value: { type: String },
    };
  }

  static get styles() {
    return [
      radioStyles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  _changeOfSelection(e) {
    this.value = e.target.getAttribute('key');
    this.nextEnabled = this.value !== undefined;
    this.data = {
      objective: this.value,
    };
  }

  _renderRadioButton(title, description, value) {
    return html`
      <label class="d2l-input-radio-label">
        <input
          ?checked=${this.value === value}
          @change=${this._changeOfSelection}
          key=${value}
          type="radio"
          name="client-onboarding-objective-group">
        ${title}
      </label>
      <div ?hidden=${this.value !== value}>
        ${description}
      </div>
    `;
  }

  render() {
    return html`
      <div class="client-onboarding-objective-description">
        Tell us who you are empowering. Let us tailor the experience to meet your goals.
      </div>
      <div class="client-onboarding-objective-options">
        ${this._renderRadioButton('My Company', 'Answer questions about your company to curate a catalog for everyone to use.', OBJECTIVES.MY_COMPANY)}
        ${this._renderRadioButton('My Team', 'Answer questions about your team to curate a catalog for everyone to use.', OBJECTIVES.MY_TEAM)}
        ${this._renderRadioButton('Just testing it out!', 'We will show you our whole catalog.', OBJECTIVES.TESTING)}
      </div>
      <div>
        ${!this.blockBack
    ? html`<d2l-button class="nova-flow-previous" @click=${this.goBack}>${this.localize('general.button-text.previous')}</d2l-button>`
    : nothing }
        <d2l-button class="nova-flow-next" primary ?disabled=${!this.nextEnabled} @click=${this.goNext}>${this.localize('general.button-text.next')}</d2l-button>
      </div>
    `;
  }

}

window.customElements.define('client-onboarding-objective', ClientOnboardingObjective);
