import '@brightspace-ui/core/components/alert/alert-toast.js';
import '@brightspace-ui/core/components/typography/typography.js';
import '@brightspace-ui/core/components/colors/colors.js';

import './shared/components/general/app-header/app-header.js';
import './shared/components/general/app-footer/app-footer.js';
import './shared/components/dialog/readonly-dialog/readonly-dialog.js';
import './main/components/general/braze/braze-content-card-container/braze-content-card-container.js';
import './shared/components/general/marketing-call-to-action/marketing-call-to-action.js';
import './shared/components/general/nova-card/nova-card.js';
import './shared/components/general/get-user-details/get-user-details.js';
import './shared/components/login/login-collect-details/login-collect-details.js';

import * as amplitude from '@amplitude/analytics-browser';
import yn from 'yn';

import { css, html, LitElement, nothing } from 'lit';
import { heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { ProviderMixin } from '@brightspace-ui/core/mixins/provider-mixin';
import { querySelectorComposed } from '@brightspace-ui/core/helpers/dom.js';
import { router } from 'lit-element-router';

import { setDocumentLocaleSettings } from '../shared/lib/common-override.js';

import { ALL_LANG_KEYS, localizerReady, setLocalizer, SUPPORTED_LANG_KEYS } from '../shared/l10n/localize.js';
import { GENERATED_TEST_ID_CATEGORIES, makePlaywrightTestId } from '../shared/helpers/playwright.js';
import { initFreshDeskWidget } from './main/lib/chat-widget-initializer/chat-widget-initializer.js';
import { initMarketoWidget } from './shared/lib/marketo/marketo.js';
import { LocalizeNova } from './shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from './shared/mixins/nova-nav/nova-nav.js';

import Client from './app-client.js';
import { FrontendLocalizer } from './shared/l10n/frontend-localizer/frontend-localizer.js';
import { initGTMWidget } from './shared/lib/gtm/gtm.js';
import routes from './app-routes.js';
import Session from './app-session.js';

class App extends LocalizeNova(ProviderMixin(router(NovaNavMixin(LitElement)))) {

  static get properties() {
    return {
      _error: { type: String },
      _loading: { type: Boolean },
    };
  }

  static get styles() {
    return [
      heading3Styles,
      css`
        :host {
          display: flex;
          flex-flow: column nowrap;
          height: 100%;
          justify-content: space-between;
          min-height: 100vh;
          width: 100%;
        }

        main {
          flex: 1 1 auto;
          outline: none;
          width: 100%;
        }

        .immersive {
          height: 100%;
          width: 100%;
        }

        .default {
          margin: 0 auto;
          max-width: 1170px;
          padding: 1.5rem 1.5rem 0 1.5rem;
          width: calc(100% - 3rem);
        }

        .admin {
          display: flex;
          flex: 1;
          flex-flow: column nowrap;
          margin: 0 auto;
          max-width: 1300px;
          padding: 5px 1.5rem 0 1.5rem;
          width: calc(100% - 3rem);
        }

        .d2l-skipnav {
          background-color: var(--d2l-color-tungsten);
          border: 2px solid var(--d2l-color-ferrite);
          border-radius: 0 0 0.8rem 0.8rem;
          color: var(--d2l-wave-white);
          cursor: pointer;
          height: auto;
          margin: 0;
          opacity: 0.8;
          outline: none;
          padding: 0.5rem 3rem;
          position: absolute;
          right: 50%;
          top: -1000px;
          transform: translateX(50%);
          -webkit-transition: top 10s ease-out;
          transition: top 10s ease-out;
          width: fit-content;
          z-index: 100;
        }

        .d2l-skipnav:active, .d2l-skipnav:focus {
          top: 0;
          -webkit-transition: none;
          transition: none;
        }

        @media (prefers-reduced-motion: reduce) {
          .d2l-skipnav {
            -webkit-transition: none;
            transition: none;
          }
        }

        @media (max-width: 615px) {
          .default {
            padding: 1.2rem 0.9rem 0;
            width: calc(100% - 1.8rem);
          }
        }
`,
    ];
  }

  constructor() {
    super();

    const session = new Session();
    const client = new Client({ session });

    this.provideInstance('d2l-nova-session', session);
    this.provideInstance('d2l-nova-client', client);

    this.session = session;
    this.client = client;
    this._routeInfo = {};
  }

  static get routes() {
    return routes;
  }

  connectedCallback() {
    this._loading = true;
    super.connectedCallback();
    document.addEventListener('nova-details-loaded', async() => {
      this.style.setProperty('--d2l-branding-primary-color', this.session.appDetails.accentColour);
    });

    document.addEventListener('nova-ready', async() => {
      await this.setLanguage();
      this.setupAmplitude();

      const { query } = this._routeInfo;

      if (
        this.session.hasFeature('redirectToSkillsWave') &&
        (window.location.host.includes('nova.dev.brightspace.com') || window.location.host.includes('wave.d2l.com'))
      ) {
        this.navigateWithoutHistory('/skillswave-redirect');
      }

      let isVerified = false;
      if (this.session.isFromAuth0) {
        isVerified = yn(this.session.user?.getExtraAttribute('emailVerified'));
        if (!isVerified) {
          if (query?.code === 'success' && yn(query?.success)) {
            this.session.user.setExtraAttribute('emailVerified', true);
            this.client.setUser(this.session.user);
            isVerified = yn(this.session.user?.getExtraAttribute('emailVerified'));

            if (isVerified) {
              this.navigateWithoutHistory('/activities');
            }
          }
        }

        if (!isVerified) {
          this.navigateWithoutHistory('/thankyou-signup');
        }
      }

      if (this.session?.user?.tenantId) {
        const tenant = await this.client.fetchTenant(this.session.tenantId);
        this.client.features = await this.client.getFeatures(this.session.tenantId);
        if (tenant?.accentColour) this.style.setProperty('--d2l-branding-primary-color', tenant.accentColour);
        const isVerifiedUser = this.session.isPublicPortal ? !this.session.isPublicPortalGuest && this.session.isFromAuth0 && isVerified : true;
        const email = this.session.user.email || (this.session.user.userId.includes('@') ? this.session.user.email : '');
        if ((tenant.type !== 'provider' && email && isVerifiedUser)) initFreshDeskWidget(email, this.session.user.getDisplayName(), this.session.user.getSetting('language'));
      }
      if (this.session.hasFeature('marketing')) {
        await initMarketoWidget();
        initGTMWidget ();
      }
      this._loading = false;
      await this._setView();

      if (this.session._user?.email) {
        const response = await this.client.getExtraUserInfoRequired(this.session._user.email);
        if (response.extraInfoRequired) this.navigateWithoutHistory('/login/get-user-details');
      }

      if (this.session.loginAttributes?.length > 0) {
        const relayState = this.session.needsOnboarding
          ? '/onboarding'
          : this._routeInfo?.query?.relayState || window.location.pathname;
        this.navigateWithoutHistory(`/login-details?relayState=${relayState}`);
      }
    });

    document.addEventListener('nova-error-loading', async e => {
      this._loading = false;
      if (e.detail?.status === 503) {
        this.navigateWithoutHistory('/maintenance');
        this._setView();
      } else {
        this.navigate('/error');
      }
    });

    document.addEventListener('nova-error', async e => {
      const message = e.detail.message || this.localize('app-nova.catchAllErrorMessage');
      const type = e.detail.type || 'critical';
      this._openErrorToast(message, type, e.detail.noAutoClose, e.detail.buttonText);
    });

    document.addEventListener('nova-hide-freshchat-widget', () => {
      const sheet = document.getElementById('freshchat-style-sheet').sheet;
      if (sheet.rules.length > 0) {
        return;
      }

      const style = `#fc_frame {
        display: none;
      }`;
      sheet.insertRule(style, 0);
    });

    document.addEventListener('nova-show-freshchat-widget', () => {
      const sheet = document.getElementById('freshchat-style-sheet').sheet;
      for (let i = 0; i < sheet.rules.length; i++) {
        sheet.deleteRule(i);
      }
    });

    document.addEventListener('nova-change-language', async e => {
      await this.setLanguage(e.detail.language);
    });
  }

  render() {
    if (this._loading) return nothing;
    const layout = this._resolveDataLayout();
    const noMargin = this.view.tagName === 'VIEW-HOME' || layout === 'admin';
    return html`
      ${this._skipNavTemplate()}
      ${this._showDefaultView ? html`<app-header></app-header>` : nothing }
      ${this._marketingC2A()}
      <main class=${layout} id="mainContainer">
        ${this._routeInfo?.route !== 'maintenance' ? html`<braze-content-card-container></braze-content-card-container>` : nothing }
        ${this.view}
        ${this._alertTemplate()}
        <readonly-dialog></readonly-dialog>
      </main>
      ${(this._showDefaultView && this.view.tagName !== 'THANKYOU-SIGNUP' && this.view.tagName !== 'AUTH-ERROR' && this.view.tagName !== 'VIEW-ONBOARDING') ? html`<app-footer ?no-margin=${noMargin}></app-footer>` : nothing }
    `;
  }

  async router(route, params, query, data) {
    const layout = this._resolveDataLayout(data);

    const newRouteInfo = { route, params, query, data, layout };
    if (JSON.stringify(this._routeInfo) === JSON.stringify(newRouteInfo)) return;
    this._routeInfo = newRouteInfo;
    await this._setView();
  }

  async setLanguage(targetLang = undefined) {
    let language = targetLang;
    if (!language) {
      const browserLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
      language = this._determineSupportedLanguage(browserLanguage);
      if (this.session.user.hasSetting('language')) {
        language = this.session.user.getSetting('language');
      } else if (this._showDefaultView) {
        this.client.setUserSetting('language', language);
      }
    }

    setLocalizer(language, FrontendLocalizer);
    /* Wait for resources to be loaded before setting the document title, otherwise it won't have translations and will result in a
       blank title for the login page
       This is a bit hacky, other solutions welcome
       REF: https://skillswave.atlassian.net/browse/ENG-231
    */
    await localizerReady();

    // const language = 'en'; // Keeping this here to easily revert if something blows up
    const timezoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setDocumentLocaleSettings(language, timezoneIdentifier);
    document.documentElement.setAttribute('lang', language);
    document.documentElement.setAttribute('data-timezone', JSON.stringify({ name: this._prettyPrintTimezone(timezoneIdentifier), 'identifier': timezoneIdentifier }));

    // If user came from Auth0, update its language in Auth0 too
    if (this.session.isFromAuth0) {
      const auth0Id = this.session.user.getExtraAttribute('id');
      await this.client.updateAuth0User(auth0Id, { 'lang': language });
    }

    if (targetLang) {
      await this.client.setUserSetting('language', targetLang);
      window.location.reload();
    }
  }

  setupAmplitude() {
    if (this.session.appDetails.amplitudeEnabled) {

      const options = { defaultTracking: true };

      amplitude.init(
        this.session.appDetails.amplitudeKey,
        this.session.user.guid,
        options
      );
    }

  }

  _alertButtonClicked() {
    const buttonEvent = new CustomEvent('toast-button-press', {
      bubbles: true,
      composed: true,
    });
    document.dispatchEvent(buttonEvent);
  }

  _alertTemplate() {
    const playwrightId = makePlaywrightTestId(GENERATED_TEST_ID_CATEGORIES.toastText, this._error);
    return html`
      <d2l-alert-toast id="toast" type="critical" data-testid="${playwrightId}" no-auto-close button-text @d2l-alert-toast-button-press=${this._alertButtonClicked}>
        ${this._error}
      </d2l-alert-toast>
    `;
  }

  _closeErrorToast() {
    const toast = this.shadowRoot.getElementById('toast');
    if (toast) {
      toast.open = false;
    }
  }

  _determineSupportedLanguage(languageCode) {
    let languageKeys;
    if (this.session.hasFeature('showUnApprovedLanguages')) {
      languageKeys = ALL_LANG_KEYS;
    } else {
      languageKeys = SUPPORTED_LANG_KEYS;
    }
    const lowerCaseLangCode = languageCode.toLocaleLowerCase();
    if (languageKeys.includes(lowerCaseLangCode)) {
      return lowerCaseLangCode;
    }
    // If specific lang with subtag not found (e.g., 'fr-ca'), then check primary tag (e.g., just 'fr')
    const primaryLang = lowerCaseLangCode.split('-')[0];
    if (languageKeys.includes(primaryLang)) {
      return primaryLang;
    }
    return 'en';
  }

  _marketingC2A() {
    if (this.session.loggedIn && this.session.hasFeature('marketing')) {
      return html`
        <marketing-call-to-action
          .removeMargin="${this._routeInfo.route === 'view-activity'}"
          .learnerTerminology="${this.session.tenant.learnerTerminology}">
        </marketing-call-to-action>`;
    }
    return nothing;
  }

  _openErrorToast(text, type, noAutoClose = true, buttonText = undefined) {
    this._error = text;
    const toast = this.shadowRoot.getElementById('toast');
    if (toast) {
      toast.open = true;
      toast.type = type;
      toast.noAutoClose = noAutoClose;
      toast.buttonText = buttonText;
    }
  }

  get _showDefaultView() {
    return this.session.loggedIn && this._resolveDataLayout() !== 'immersive';
  }

  _resolveDataLayout(data = undefined) {
    if (data) {
      return data.layout || 'default';
    }

    if (this._routeInfo.data?.getDynamicLayout) {
      return this._routeInfo.data.getDynamicLayout(this.session);
    }

    if (this._routeInfo.data?.layout) {
      return this._routeInfo.data.layout;
    }

    if (this._routeInfo.layout) {
      return this._routeInfo.layout;
    }

    return 'full-width';
  }

  async _setView(OverrideView) {
    if (this._loading) return;
    const { route, params, query, data } = this._routeInfo;
    // If we aren't logged in and the user is trying to access a protected route, redirect to login
    const publicPortalEnabled = this.session.appDetails?.publicPortalEnabled ?? false;
    if (data.protected(this.session)) {
      if (publicPortalEnabled) {
        const loginLink = await this.client.createGuestLogin({ relayState: this.session.relayState });
        const url = new URL(loginLink.magicLink);
        await this.session.verifyMagicLink(url.searchParams.get('magic'));
        await this.session.loadSession();
      } else {
        this.session.relayState = `${window.location.pathname}${window.location.search}`;
        this.navigateWithoutHistory('/login');
        return;
      }
    }

    const applyParamsToUrl = url => {
      if (!params) return url;
      return Object.keys(params).reduce((acc, key) => acc.replace(`:${key}`, params[key]), url);
    };

    if (data?.type === 'redirect') {
      this.navigateWithoutHistory(applyParamsToUrl(data.url));
      return;
    }

    if (publicPortalEnabled) this.client.setMetaTag('robots', 'index, follow');

    const View = data.getDynamicView ? data.getDynamicView(this.session) : data.View;
    this.view = new View();
    if (OverrideView) this.view = new OverrideView();

    // If lang key not provided or lang key not found, default to app-nova title
    let localizedTitle = this.localize(data.documentTitleLangKey);
    if (!localizedTitle) localizedTitle = this.localize('app-nova.documentTitle');
    this.client.setDocumentTitle(localizedTitle);

    Object.assign(this.view, { route, params, query });
    window.scrollTo(0, 0); // Individual views may want to control this instead some day
    this._closeErrorToast();
    this.update();
  }

  /**
   * Provides accessibility/screen-reader users the ability to skip navigation menu (header)
   * and go directly to the main content of the page.
   * Location in host html is the first DOM element.
   * TODO: Don't place back into app-nova render until all views in app-routes.js have a focusable element without a shadowRoot.
   */
  _skipNavTemplate() {
    return html`
      <button @click=${this._handleSkipNav} class="d2l-skipnav d2l-heading-3">${this.localize('app-nova.skipToMain')}</button>
    `;
  }

  _handleSkipNav() {
    const elem = querySelectorComposed(document, 'main') ||
			querySelectorComposed(document, '[role="main"]') ||
			querySelectorComposed(document, 'h1');
    if (elem) {
      elem.tabIndex = -1;
      elem.focus();
    } else {
      console.warn('No main content found to skip to');
    }
  }

  _prettyPrintTimezone(timezone) {
    const [region, city] = timezone?.split('/') || [null, null];
    if (!region || !city) {
      return timezone;
    }

    return `${region} - ${city}`;
  }
}

window.customElements.define('app-nova', App);
