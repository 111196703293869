import '../../../components/nova-flow/nova-flow/nova-flow.js';
import '../../../components/nova-flow/nova-flow-step/nova-flow-step.js';
import './steps/client-onboarding-industry/client-onboarding-industry.js';
import './steps/client-onboarding-department/client-onboarding-department.js';
import './steps/client-onboarding-focus/client-onboarding-focus.js';
import './steps/client-onboarding-welcome/client-onboarding-welcome.js';
import '../../../../shared/components/nova-animation/nova-animation.js';

import { css, html, LitElement } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../../shared/mixins/nova-nav/nova-nav.js';
import { OBJECTIVES } from './steps/client-onboarding-objective/client-onboarding-objective.js';

const STEPS = {
  START: 'welcome',
  OBJECTIVE: 'objective',
  INDUSTRY: 'industry',
  DEPARTMENT: 'department',
  FOCUS: 'focus',
  END: 'end',
};

export default class ViewClientOnboarding extends LocalizeNova(RequesterMixin(NovaNavMixin(LitElement))) {

  static get properties() {
    return {
      _companyName: { type: String },
      _lightcastCompanyId: { type: String },
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        height: 100%;
        width: 100%;
      }
    `;
  }

  constructor() {
    super();
    this._companyName = '';
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    this._companyName = this.session.tenant.name;
    // TODO: Leaving this for testing, will remove later
    this._companyName = 'D2L';
    const lightcastCompanyData = await this.client.searchLightcast(this._companyName);
    if (lightcastCompanyData.length) {
      this._lightcastCompanyId = lightcastCompanyData[0].id;
    }

    this.dispatchEvent(new CustomEvent('nova-hide-freshchat-widget', { bubbles: true, composed: true }));
  }

  disconnectedCallback() {
    document.dispatchEvent(new CustomEvent('nova-show-freshchat-widget', { bubbles: true, composed: true }));
    super.disconnectedCallback();
  }

  async _completedFlow(e) {
    const data = e.target.data;

    const promises = [];
    let industrySkills, departmentSkills;

    if (data[STEPS.INDUSTRY] && data[STEPS.INDUSTRY].industryList?.length) {
      promises.push(this.client.lightcastSkills({
        companyId: this._lightcastCompanyId,
        skillCategories: data[STEPS.INDUSTRY].industryList,
      }).then(result => industrySkills = result.skills));
    }
    if (data[STEPS.DEPARTMENT] && data[STEPS.DEPARTMENT].department) {
      promises.push(this.client.lightcastSkills({
        companyId: this._lightcastCompanyId,
        lotCareerArea: data[STEPS.DEPARTMENT].department,
      }).then(result => departmentSkills = result.skills));
    }

    await Promise.all(promises);

    const streams = {};

    if (industrySkills && industrySkills.length) {
      streams.industryStream = {
        displayName: 'Recommended for your company',
        path: 'tenant-onboarding-industry',
        skills: industrySkills,
      };
    }
    if (departmentSkills && departmentSkills.length) {
      streams.departmentStream = {
        displayName: 'Recommended for your department',
        path: 'tenant-onboarding-department',
        skills: departmentSkills,
      };
    }

    this.client.createStreams(streams);

    this.navigateWithoutHistory('/');
  }

  _shouldSkipDepartment(data) {
    return data[STEPS.OBJECTIVE].objective !== OBJECTIVES.MY_TEAM;
  }

  render() {
    return html`
      <nova-flow @completed=${this._completedFlow} ?hideProgressBar=${true} ?hideNavigationButtons=${true}>
        <client-onboarding-welcome
          step="${STEPS.START}"
          next-enabled
        ></client-onboarding-welcome>
        <client-onboarding-objective
          step="${STEPS.OBJECTIVE}"
        ></client-onboarding-objective>
        <client-onboarding-industry
          step="${STEPS.INDUSTRY}"
          lightcast-company-id="${this._lightcastCompanyId}">
        </client-onboarding-industry>
        <client-onboarding-department
          step="${STEPS.DEPARTMENT}"
          .shouldSkip=${this._shouldSkipDepartment}
          lightcast-company-id="${this._lightcastCompanyId}">
        </client-onboarding-department>
        <client-onboarding-focus
          step="${STEPS.FOCUS}"
          next-enabled
        ></client-onboarding-focus>
        <nova-flow-step step="${STEPS.END}" next-enabled>
          <div>
            <h1>Welcome to a world of learning</h1>
            <nova-animation src="/assets/rive/success_page.riv" height='400' width='400'></nova-animation>
            <d2l-button id="nova-flow-finish" primary>${this.localize('general.button-text.finish')}</d2l-button>
          </div>
        </nova-flow-step>
      </nova-flow>
    `;
  }
}

window.customElements.define('view-client-onboarding', ViewClientOnboarding);
