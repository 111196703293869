import { css } from 'lit';

export const skillswaveBodySmallStyles = css`
  .skillswave-body-small {
    color: var(--d2l-color-ferrite);
    font-family: 'Poppins', sans-serif;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 0.8rem;
  }
`;

export const skillswaveHeader1Styles = css`
  .skillswave-heading-1 {
    color: var(--d2l-color-ferrite);
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 36px;
  }
`;

export const skillswaveParagraphSubtleStyles = css`
  .skillswave-paragraph-subtle {
    color: var(--d2l-color-tungsten);
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
  }
`;

export const skillswaveButtonStyles = css`
  .skillswave-button-text {
    color: var(--d2l-color-regolith);
    font-family: 'Poppins', sans-serif;
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 18px;
  }
`;
