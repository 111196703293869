export const NovaFlowStepMixin = superclass => class extends superclass {

  static get properties() {
    return {
      step: { type: String },
      nextEnabled: { type: Boolean, attribute: 'next-enabled' },
      skippable: { type: Boolean },
      blockBack: { type: Boolean, attribute: 'block-back' },
      data: { type: Object },
      shouldSkip: { type: Function },
      stepIndex: { type: Number },
      stepCount: { type: Number },
    };
  }

  constructor() {
    super();
    this.step = 'none';
    this.nextEnabled = false;
    this.skippable = false;
    this.blockBack = false;
    this.data = undefined;
    this.stepIndex = -1;
    this.stepCount = 0;

    this.shouldSkip = () => false;
  }

  connectedCallback() {
    super.connectedCallback();

    const parentFlow = this.parentElement;
    if (!parentFlow) {
      return;
    }

    const steps = parentFlow._steps || [];
    this.stepIndex = steps.findIndex(step => step.name === this.step);
    this.stepCount = steps.length;
  }

  firstUpdated() {
    this.slot = this.step;
  }

  updated(changedProperties) {
    if (changedProperties.has('nextEnabled') && this.nextEnabled !== undefined) {
      this.dispatchEvent(new CustomEvent('nova-flow-step-next-enabled', {
        detail: {
          step: this.step,
          nextEnabled: this.nextEnabled,
        },
        bubbles: true,
      }));
    }
  }

  goNext() {
    if (!this.nextEnabled) {
      return;
    }

    this.dispatchEvent(new CustomEvent('nova-flow-step-next', {
      bubbles: true,
    }));
  }

  goBack() {
    this.dispatchEvent(new CustomEvent('nova-flow-step-back', {
      bubbles: true,
    }));
  }

  finish() {
    this.dispatchEvent(new CustomEvent('nova-flow-step-finish', {
      bubbles: true,
    }));
  }
};
